/* Existing Styles (unchanged) */
.offer-section .toppara h2 {
  font-family: "Space Grotesk";
  font-size: 34.44px;
  font-weight: 400;
  line-height: 45.6px;
  letter-spacing: -0.7px;
}

.offer-section .toppara p {
  font-family: "Open Sans";
  font-size: 22.5px;
  font-weight: 400;
  line-height: 33.6px;
  letter-spacing: -0.5px;
}

.offer-section .toppara {
  margin-left: 20px;
}

.offer-section .offer-card .number {
  font-family: "Open Sans";
  font-size: 128px;
  line-height: 123.6px;
  letter-spacing: -1px;
  font-weight: 800;
  color: #3f6c88;
  opacity: 0.25;
  position: absolute;
  bottom: -35px;
}

.offer-section .offer-card .card-title.h5 {
  font-family: "Open Sans";
  font-size: 26.58px;
  font-weight: 700;
  line-height: 33.6px;
  letter-spacing: -0.7px;
}

.offer-section .offer-card .card-text {
  font-family: "Open Sans";
  font-size: 17.02px;
  font-weight: 400;
  line-height: 25.2px;
  letter-spacing: -0.3px;
  text-align: left;
}

.offer-section .carousel-btn {
  width: 40px;
  height: 40px;
  font-size: 20px;
  border-radius: 12px;
  background: #00000033;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-row{
margin-left: 60px;}

.offer-section .sliderCard {
  width: 100%;
  height: 300px;
  overflow-y: hidden;
  padding: 56px 32px 72.47px 32px;
  border-radius: 30px;
}
@media (max-width: 768px){
  .carousel-row{
    margin-left: 0px;
  }
}
/* Media Queries for Mobile */
@media (max-width: 768px) and (pointer: coarse) {
  .offer-section .toppara p {
    font-size: 18px;
  }

  .offer-section .toppara h2 {
    font-size: 28px;
  }

  .offer-section .sliderCard {
    width: 100%; /* Ensure the card takes the full width on mobile */
    padding: 30px 20px;
  }

  /* Adjust carousel container for mobile */
  .carousel-slides {
    width: 100%; /* Full width for mobile */
    display: flex;
    flex-direction: row;
    transition: transform 0.5s ease-in-out;
  }

  .carousel-slides .slidercol {
    flex: 0 0 100%; /* Each slide takes 100% of the container */
    margin-right: 0; /* Remove extra margin on mobile */
  }
}

/* Media Queries for Tablet/Desktop */
@media (max-width: 1500px) {
  .carousel-slides.d-flex.gap-5 .slidercol {
    margin-right: -22px;
  }
}
