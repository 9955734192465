/* General Styles */
*{
  scroll-behavior: smooth; 
}
.testimonials-section {
  background-color: #FFFFFF;
  position: relative;

}

.testimonials-section .testimonial-bg-img {
  position: absolute;
  background-image: url("../../public/images/testimonial-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 40px;
  left: 120px;
  width: 100%;
  height: 100%;

  transform: scale(0.70);
}

.testimonials-section .column {
width: 380px;
  z-index: 1;

}
.testimonials-section .column.larger {
  transform: scale(1.1, 1.0); 

  z-index: 2; 
}

.testimonials-section .testimonial-card {
  padding: 20px;
  transition: height 0.3s ease;
  box-shadow: 0px 0px 10px 0px #0000001A;
  border-radius: 30px;
  background-color: #fff;
}

.testimonials-section .social-icon {
  width: 50px;
  height: 50px;
  color: #0077b5; }

.testimonials-section .position-sec .card-title {
  font-size: 16px;
}
.testimonials-section .position-sec {
  width: 160px;
}

.testimonials-section .para {
  font-size: 22.5px;
}

.testimonials-section .head {
  font-family: "Space Grotesk";
  font-size: 40px;
  font-weight: 700;
}

img.rounded-circle.testi-img {
  width: 80px;
  height: 80px;
  object-fit: cover; 
  aspect-ratio: 1 / 1; 
  border-radius: 50%; 
}

/* iOS Specific Styles */
@media (max-width: 768px) {
  .testimonials-section .column {
    margin-right: -105px; /* Margin for iOS */
    margin-left: 10px; /* Margin for iOS */
    overflow-y: scroll; /* Enable vertical scrolling */
  }
  
  .testimonials-section .column.larger {
    margin-right: 400px; /* Margin for larger column on iOS */
  }
  .testimonial-row {
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: space-between; /* Space between items */
  }

  .arrow-icon {
    cursor: pointer; /* Change cursor to pointer for better UX */
    font-size: 24px; /* Adjust size if needed */
    color: #000000; /* Ensure arrow color contrasts */
    z-index: 2; /* Bring arrows to the front */
  }

  .left-arrow {
   
    width: 38px;
    align-items: center;
    margin-right:0px; /* Push left arrow to the left */
  }

  .right-arrow {
  
    width: 38px;
    margin-left: -15px; /* Push right arrow to the right */
  }
  .testimonials-section .position-sec .card-title {
    
    font-size: 15px;
  }
  .testimonials-section .testimonial-card{
    margin-bottom: 15px;
  }
}

@media (max-width: 576px) {
  .testimonials-section .social-icon {
    width: 45px;
    height: 45px;
    margin-right: -15px;
  }
 
  img.rounded-circle.testi-img {
    width: 50px;
    height: 50px;
  }

  .testimonials-section .testimonial-bg-img {
    transform: scale(1.2, 1.3);
  }

  .testimonials-section .position-sec {
    width: 110px;
  }
  
  .testimonials-section .position-sec p {
    font-size: 12px;
  }



  .testimonials-section .feedback {
    font-size: 13px;
  }
 
}

/* Android Specific Styles */
@media (max-width: 768px) and (pointer: coarse) {
  .testimonials-section .column {
    width: 320px;
    margin-right: 0; 
    margin-left: -15px;
    
  }

}
