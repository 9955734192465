.custom-navbar {
  box-shadow: 0px 2px 70px 0px #0000000f;

}

.custom-button {
  background-color: #1e1515;
  box-shadow: 0px 1px 3px 0px #2f2b431a;
  color: #ffffff;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 20px;
  border: none;
  border-radius: 37px;
}

.navbar .nav-link {
  color: #00000080;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.20000000298023224px;
}
.navbar {
  position: fixed; /* Fix the position */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, 5%); /* Align perfectly at the center */
  border-radius: 30px;
  height: 102px;
  z-index: 99999;
  width: 100%; /* Make it responsive */
}
.navbar .nav-flex {
  display: flex;
  gap: 25px;
  align-items: center;
}

.logo-img{
  mix-blend-mode: multiply;
}
/* Navbar.css */
.drawer {
  position: absolute; /* Fixed position to overlay the content */
  top: 8px;          /* Attach to the top */
  width: 200px; 
  right: 0;   /* Width of the drawer */
  height: 40%;    /* Full height */
  transition: right 0.3s ease; 

  z-index: 1000; 
}
.gradient-button:hover{
  color:white;
}
 .gradient-button:active {
  color: white !important;
  opacity: 0.8%;
}
.gradient-button {
  background: linear-gradient(90deg, #EB360E, #F86C1E);
  color: white; /* Text color */
  border: none; /* Remove border */
  padding: 10px 20px; /* Add padding for better appearance */
  border-radius: 10px; /* Rounded corners (optional) */
  font-weight: bold; /* Optional: Make text bold */
  transition: background-color 0.3s ease; /* Transition effect */
}
.collapse.show #drawerMenu {
  right: 0px; 
}
.d-bg {
  border-radius: 30px;
}
.d-bg a{
  margin-bottom: 5px;
}
/* Style for the close button */
.btn-close {
  background: none; /* Remove default background */
  border: none; /* Remove default border */
  color: black; /* Change color to fit your theme */
  font-size: 1.5rem; /* Adjust size as needed */
  position: absolute; /* Position it in the drawer */
  top: 0px; /* Adjust top position */
  left: 20px; /* Adjust left position */
  cursor: pointer; /* Change cursor to pointer */
}



@media (max-width: 991px) {
  .navbar {
    height: 60px;
    border-radius: 25px;
  }
  .gradient-button{
    margin-bottom: 5px;
  }

  
  button.navbar-toggler {
    font-size: 15px;
  }
  .nav-container {
    margin-top: -3px;
}
.logo-img {
  width: 55px;
  height: 57px;
  margin-top: -2px;
}
}